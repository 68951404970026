.nav-link{
	text-decoration: none;
	color: black;
}

.nav-list{
	list-style-type: none;

/*	display: flex;
	justify-content: flex-end;*/
	padding: 0;
	margin: 0;
}

.nav-item{
	float: left;
	cursor: pointer;
}

.nav-item, .nav-brand{
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	padding-right: 10px;
}

.active > a{
	color: darkgrey;
	cursor: not-allowed;
}

.navigation{
	width: 100%;
	position: sticky ;
	top: 0;
	background: lightgrey;
	border-bottom: 2px solid black;
	z-index: 998;
}

.logo{
	background: white;
}

.brand{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.nav-brand{
	font-size: 1.5em;
	font-weight: bold;
	  	color: #9a2121;
}

/*Little Navbar Icon*/

.line1, .line2, .line3{
	background: black;
	height: 3.5px;
	margin: 3.8px;
	width: 25px;
}

.nav-open{
	display: none;
	cursor: pointer;
}

.hide{
	display: none;
}
.hide-nav{
	display: none;
}

@media screen and (max-width: 800px) {
	.nav-open{
		display: inline-block;
	}
	.nav-item{
		float: none;
	}
	.nav-list{
		position: absolute;
		/*right: 1px;*/
		/*width: 30px;*/
		background: white;
		border: 1px solid black;
	}

	.nav-list-div{
		width: 150px;
	}
}
@media screen and (min-width: 801px){
	.hide-nav{
		display: inherit;
	}
}
