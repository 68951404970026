/*catalogue*/
h1{
	margin: 0;
	margin-top: .67rem;
}

.c-r{
	color: #9a2121;
}

.c-b{
	color:black;
}

.c-w{
	color: white;
}

.flex-wrap{
	display: flex;
	flex-wrap: wrap;
}

.flex-h-c{
	justify-content: center;
}

.flex-v-c{
	align-items: center;
}

.app{
	text-align: center;
}

.page{
	position: relative;
	max-width: 1020px;
	background: white;
	border-right: 1px solid black;
	border-left: 1px solid black;
}

.f100{
	font-size: 100%;
}

.popUp{
	position: fixed;
	top: 50%;
  	left: 50%;
  	background: white;
  	border: 2px solid black;
  	border-radius: 15px;
  	transform: translate(-50%, -50%);
}

.grid{
	display: grid;
}

.bodyGradient{
	position: relative;
	width: 100%;
	background:
		radial-gradient(
		circle,
		#ffffff,
		#d6d6d6)
}

.headding{
	height: 2em;
	color: black;
	border-bottom: .125rem solid black;
}
/*end of section*/

.hover:hover{
	box-shadow:rgba(0,0,0,0.8) 0px 5px 15px, inset rgba(0,0,0,0.15) 0px -10px 20px;
}

.hover-p:hover{
	box-shadow:rgba(0,0,0,0.8) 0px 5px 15px, inset rgba(0,0,0,0.15) 0px -10px 20px;
	cursor: pointer;
}

@media only screen and (max-width: 1000px){
	.page{
		max-width: 500px;
	}
}

@media only screen and (max-width:  500px){
	.page{
		max-width: 100%;
	}
}