/*h5{
	padding-top: 5px;
}*/

.container{
}

.gridWrapper{
	display: grid;
	gap: 10px;
	grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
	justify-items: center;
}

.tile{
	position: relative;
	width: 500px;
	height: 315px;
	border: 2px solid black;
	border-radius: 25px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

/*.infoPannel{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	flex-direction: column;
	
}*/

.title{
	grid-column: span 2;
	position: relative;
}

.green, .red{
	color: white;
}
.green{
	background: green;
}

.red{
	background:darkred;
}

.fontsizer{
	font-size: 100%;
}

.round{
	border-bottom-left-radius: 25px;
	border-bottom-right-radius: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.tileBubble {
	height: 50px;
}

.f3 {
	font-size: 1.4rem;
}

.img{
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	height: 65%;
	object-fit: cover;
	/*background-color: black;*/
}

.imgContain{
	object-fit: contain;
}

.featuredAliginment{
	gap:  10px;
	justify-content: space-evenly;

}

.shadow-3{
	border: none;
	border-radius: 25px;
}

.cover{
	height: 100vh;
	background: cover;
}

.cover-flex{
	display: flex;
	justify-content: center;
	align-items: center;
}

.cover-b{
	color: white;
	/*background: rgb(110,29,29);
	background: linear-gradient(90deg, rgba(110,29,29,1) 0%, rgba(189,18,18,1) 50%, rgba(255,0,0,1) 100%);*/
	background: rgb(0,0,0);
background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(48,48,48,1) 35%, rgba(87,87,87,1) 100%);
}

.vh20{
	height: 35vh;
}

.hide_item{
	display: none;
}

h4 a {
	text-decoration: none;
	color: grey;
}

@media screen and (max-width: 2065px){
	.tile{
		max-width: 400px;
	}
}

@media screen and (max-width: 1300px){
	.title{
		max-width: 400px;
	}
	.gridWrapper{
		grid-template-columns: repeat(auto-fill, minmax(290px, 400px));
	}
	.f-headline{
		font-size: 5rem;
	}
	.f1{
		font-size: 2rem;
	}
	.f2{
		font-size: 1.5rem;
	}
}

@media screen and (max-width: 900px){
	.title, .tile{
		max-width: 290px;
	}
	.gridWrapper{
		grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
	}
	.f-headline{
		font-size: 3rem;
	}
}

@media screen and (max-width:  600px){
/*	.gridWrapper{
		grid-template-columns: repeat(auto-fill, minmax(100px, ));
	}*/
	.f-headline{
		font-size: 1.15rem;
	}
	.f1{
		font-size: 1rem;
	}
	.f2{
		font-size: .75rem;
	}
}